import { FilterPaymentState } from '../../components/details-view/trip-history/helper';
import CustomerDataInterface, {
    IAuditCustomer,
    ICustomerBooking,
    ICustomerTrip,
} from '../../models/customer-data.interface';
import { SearchType } from './customer-slice';
import { FilterByDateRange } from './types';

export interface CustomerState {
    customer?: CustomerDataInterface;
    auditTrail?: {
        orgData: IAuditCustomer[];
        data: IAuditCustomer[];
        status: 'idle' | 'loading' | 'succeeded' | 'failed';
        filter: {
            auditChangedBy: {
                customer: boolean;
                support: boolean;
            }
        }
    }
    bookings?: {
        data: ICustomerBooking[];
        status: 'idle' | 'loading' | 'succeeded' | 'failed';
    },
    trips?: {
        data: ICustomerTrip[];
        filteredData: ICustomerTrip[];
        presentationData: ICustomerTrip[];
        status: 'idle' | 'loading' | 'succeeded' | 'failed';
        filter: {
            dateRange?: FilterByDateRange,
            paymentState?: FilterPaymentState,
        },
        hasOpenPayments: null | boolean
    },
    id?: string
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    search: {
        identifier: string;
        type: SearchType;
    };
    error: any;
    errorDialog?: {
        message: string;
        title: string;
        code: string;
    }
}

export const initialCustomerState: CustomerState = {
    customer: undefined,
    auditTrail: {
        orgData: [],
        data: [],
        status: 'idle',
        filter: {
            auditChangedBy: {
                customer: false,
                support: false,
            }
        }
    },
    bookings: {
        data: [],
        status: 'idle',
    },
    trips: {
        hasOpenPayments: null,
        data: [],
        filteredData: [],
        presentationData: [],
        status: 'idle',
        filter: {
        }
    },
    id: undefined,
    search: {
        identifier: '',
        type: undefined,
    },
    status: 'idle',
    error: null,
    errorDialog: undefined,
};