import React from 'react';
import { FormFieldLabel, Table } from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppSelector } from "../../../redux/hooks";
import { selectCustomer } from "../../../redux/slices/customer-slice";
import {
  PaymentProfileCreditCardData,
  PaymentProfilePayPalData,
  PaymentProfileSepaDirectDebitData
} from "../../../models/customer-data.interface";
import DetailComponent from "../../base/detail-component";
import { formatter } from '../../../utils/time-utils';


export default function PaymentDataView() {
  const customer = useAppSelector(selectCustomer());
  let hasOpenPayments: string;
  switch (customer.customer?.paymentAcceptance) {
    case 'REJECTED': {
      hasOpenPayments = 'Yes';
      break;
    }
    case 'COMPLETED': {
      hasOpenPayments = 'No';
      break;
    }
    case 'NO_PAYMENT_METHOD_FOUND': {
      hasOpenPayments = 'PM not found';
      break;
    }
    case 'PAYMENT_METHOD_IS_NOT_ACTIVE': {
      hasOpenPayments = 'PM not Active';
      break;
    }
    default:
      hasOpenPayments = 'Unknown';
  }
  function renderItem(pm: (PaymentProfileCreditCardData | PaymentProfileSepaDirectDebitData | PaymentProfilePayPalData)) {
    return (
      <Table.Tr key={pm.paymentProfileId} className={`table-row ${pm.priority === 1 && 'default-pm'}`}  >
        <Table.Td>{pm.paymentMethod}</Table.Td>
        <Table.Td>{pm.state}</Table.Td>
        <Table.Td>{pm.priority}</Table.Td>
        {renderItemDetails(pm)}
        <Table.Td>{pm.stateTimestamp ? formatter.format(new Date(pm.stateTimestamp)) : '-'}</Table.Td>
      </Table.Tr>
    );
  }

  function renderItemDetails(pm: (PaymentProfileCreditCardData | PaymentProfileSepaDirectDebitData | PaymentProfilePayPalData)) {
    switch (pm.paymentMethod) {
      case 'CREDIT_CARD': {
        const pmdetails = pm as PaymentProfileCreditCardData;
        return (<Table.Td>{pmdetails.creditCard?.cardNetwork}(*** {pmdetails.creditCard?.last4}), expiry: {pmdetails.creditCard?.expiryMonth}/{pmdetails.creditCard?.expiryYear}</Table.Td>)
      }
      case 'SEPA_DIRECT_DEBIT': {
        const pmdetails = pm as PaymentProfileSepaDirectDebitData;
        return (<Table.Td>{pmdetails.sepaDirectDebit?.bankName}</Table.Td>)
      }
      case 'PAYPAL': {
        const pmdetails = pm as PaymentProfilePayPalData;
        return (<Table.Td>{pmdetails.paypal?.email}</Table.Td>)
      }
      default:
        return (<Table.Td>---</Table.Td>)
    }
  }

  return (
    <>
      <div>
        <div className='payment-details'>
          <DetailComponent id='paymentMethodStatus' label='Payment Method Status' value={customer.customer?.paymentMethodStatus} />
        </div>
      </div>
      <div data-testid="tableComponent">
        {customer.customer?.paymentProfiles && customer.customer?.paymentProfiles?.length > 0 && (
          <Table colored={true} >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Type</Table.Th>
                <Table.Th>State</Table.Th>
                <Table.Th>Priority</Table.Th>
                <Table.Th>Details</Table.Th>
                <Table.Th>Updated At</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{customer.customer?.paymentProfiles?.map((pm) => renderItem(pm))}</Table.Tbody>
          </Table>
        )}

        {(!customer.customer?.paymentProfiles || customer.customer?.paymentProfiles?.length === 0) && (<FormFieldLabel testId="noPaymantProfilesLabel">No Payment Methods</FormFieldLabel>)}
      </div>
    </>
  );
}
