import React from 'react';
import './style.css';
import {Button, Heading, LogoBanner, Spinner} from '@vwfs-bronson/bronson-react';
import SearchContainerView from '../../components/search-container-view';
import {useAppSelector} from "../../redux/hooks";
import {selectCustomer} from "../../redux/slices/customer-slice";
import {removeAuthConfig} from "../../utils/auth-utils";
import {useAuth} from "react-oidc-context";

export default function SearchViewPage() {
  const customer = useAppSelector(selectCustomer());
  const auth = useAuth();
  const { user} = useAuth();
  const logout = () => {
    removeAuthConfig();
    auth.removeUser();
    auth.signoutRedirect({
      id_token_hint: user?.id_token,
    });
    auth.clearStaleState();
  }

  return (
    <div className="App">
      <div className="column">
        <div className="c-header__wrapper" data-testid="heading">
          <div data-testid="CustomerSearchLabel" className='c-header  c-header--static'>
            <div className='c-header__content-wrapper'>
              <LogoBanner
                imageProps={{
                  alt: 'Back-Office MobilityApp',
                  src: "https://cdn.bronson.vwfs.tools/bluelabel/v/15.6.0/img/logo.svg",
                }}
                className='banner'
                logoHeight='180px'
              />
              <div className='banner-heading' data-testid="HeaderHeading">
                <Heading level={6} className="display-inline">
                  Back-Office MobilityApp
                </Heading>
              </div>
            </div>
          </div>
        </div>
        <div className='navigation-bar'>
          <div className='navigation-right'>
            <div className='navigation-button' data-testid="logout-button">
              <Button icon='logout' ariaLabel='Logout' name='logout' secondary
                      onClick={logout}/>
            </div>
          </div>
        </div>
        <div id="body-content" className="container">
        <div className="search-container">
            <SearchContainerView/>
          </div>
        </div>
      </div>
      <Spinner fullPage busy={customer.status == 'loading'} testId='searchSpinner'/>
    </div>
  );
}
