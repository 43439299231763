import React, { useState } from 'react';
import { FormFieldLabel, InfoIcon, Modal, Spinner, Table, Tooltip } from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetErrorDialog, selectCustomer, selectTrips } from "../../../redux/slices/customer-slice";
import {
  ICustomerTrip,
  ICustomerTripBilling,
  ICustomerTripBillingState, TripStatusEnum,
} from "../../../models/customer-data.interface";
import InvoicesModal from '../../base/invoices-modal';
import { Icon } from '@vwfs-bronson/bronson-react/dist/Icon';
import { getPaymentState } from './helper';
import { formatter } from '../../../utils/time-utils';

export default function TripHistoryDataView() {
  const customer = useAppSelector(selectCustomer());
  const trips = useAppSelector(selectTrips());
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const handleOnClose = () => {
    setShowModal(false);
    setInvoices([]);
  }
  const handleOnModalShow = (invoices) => {
    setInvoices(invoices);
    setShowModal(true);
  }

  const handleConfirmErrorDialog = () => {
    dispatch(resetErrorDialog(undefined));
  };


  function renderStatusIcon(state: ICustomerTripBillingState) {
    return <Tooltip content={state} placement='top'>
      <span>
        {
          state === 'REJECTED' || state === 'CANCELED' ?
            <Icon className="c-badge__icon c-icon--[semantic-close] semantic-close c-icon--xsmall" /> :
            <Icon className="c-badge__icon c-icon--[semantic-checkmark] semantic-checkmark c-icon--xsmall" />
        }
      </span>
    </Tooltip>
  }

  function renderInvoiceTypeAndPaymentStatus(type, status) {
    switch (type) {
      case 'CHARGE':
        return `Trip (${status})`
      case 'FEE':
        return `Fee (${status})`
      case 'REFUND':
        return `Refund (${status})`
    }
  }

  function renderBillingInvoices(billings: ICustomerTripBilling[], tripState: TripStatusEnum) {
    const billingMap = billings.reduce((map, billing) => {
      if (!map[billing.invoiceNumber]) {
        map[billing.invoiceNumber] = [billing];
      } else {
        map[billing.invoiceNumber].push(billing);
      }

      return map;
    }, {} as { [invoiceNumber: string]: ICustomerTripBilling[] })

    const invoiceNumbers = Object.keys(billingMap);

    return (
      <ul className='row-ul'>
        {
          invoiceNumbers.map(invoiceNumber => {
            const items = billingMap[invoiceNumber];

            const tripItem = items.find(item => item.type === 'CHARGE') ? [items.find(item => item.type === 'CHARGE')] : [];
            const feeItems = items.filter(item => item.type === 'FEE');

            const paymentStatus = getPaymentState(tripState, items);

            return <>
              {tripItem.map(item =>
                <li key={item.invoiceNumber + '_li'} className='row-li'>
                  <span key={item.invoiceNumber + '_span'} className='row-li-inline'>
                    <a onClick={() => handleOnModalShow(items)}>
                      {renderInvoiceTypeAndPaymentStatus(item?.type, paymentStatus)}
                    </a>
                  </span>
                </li>
              )}
              {feeItems?.map(item =>
                <li key={item.invoiceNumber + '_li'} className='row-li'>
                  <span key={item.invoiceNumber + '_span'} className='row-li-inline'>
                    <a onClick={() => handleOnModalShow(items)}>
                      {renderInvoiceTypeAndPaymentStatus(item?.type, paymentStatus)}
                    </a>
                  </span>
                </li>
              )}
            </>
          })
        }
      </ul >
    )
  }

  function renderItem(trip: ICustomerTrip) {
    if (!trip) {
      return null;
    }

    return (
      <Table.Tr key={trip.id} className="table-row">
        <Table.Td>{trip.id.substring(0, 9) + '...'}<InfoIcon className="semantic-info" testId="infoIconInvoicesData">                                                                                                                                                                     {trip.id}</InfoIcon></Table.Td>
        <Table.Td>{trip.state}</Table.Td>
        <Table.Td>{trip.travelledMileage.value} {trip.travelledMileage.unit}</Table.Td>
        <Table.Td>{trip.vehicle.vehicle.model.vehicleType}</Table.Td>
        <Table.Td>{trip.tripPeriod ? formatter.format(new Date(trip.tripPeriod.startTimestamp)) :
          (trip.reservation?.reservationPeriod?.startTimestamp ? formatter.format(new Date(trip.reservation.reservationPeriod.startTimestamp)) : '-')}</Table.Td>
        <Table.Td>{trip.billings.length > 0 ? renderBillingInvoices(trip.billings, trip.state) : '-'}</Table.Td>
      </Table.Tr>
    );
  }

  return (
    <>
      {trips.presentationData && trips.presentationData?.length > 0 && (
        <Table colored={true} testId="trip-history-table">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Trip Id</Table.Th>
              <Table.Th>Trip State
                <InfoIcon className="semantic-info" testId="infoIconInvoicesData">
                  <b>Upcoming</b>: Await 3DS challenge<br />
                  <b>Vehicle Ready</b>: All preconditions are fulfilled. User can start a trip.<br />
                  <b>In Trip</b>: User is in an active trip.<br />
                  <b>In Stopover</b>: User trip is paused. User can resume trip. <br />
                  <b>Completed</b>: User trip has ended.<br />
                  <b>Canceled</b>: User reservation was canceled.<br />
                </InfoIcon>
              </Table.Th>
              <Table.Th>Distance</Table.Th>
              <Table.Th>Vehicle type</Table.Th>
              <Table.Th>Start Time</Table.Th>
              <Table.Th>Invoices<InfoIcon className="semantic-info" testId="infoIconInvoicesData">Click on invoice for more details</InfoIcon></Table.Th>
            </Table.Tr >
          </Table.Thead >
          <Table.Tbody>{trips?.presentationData?.map((trip) => renderItem(trip))}</Table.Tbody>
        </Table >
      )
      }

      <InvoicesModal invoices={[...invoices]} showModal={showModal} handleOnClose={handleOnClose} />

      {
        ((!trips.presentationData || trips.presentationData?.length === 0) && trips?.status !== 'failed') && (
          <FormFieldLabel testId="noTripsLabel">No Trips for Customer</FormFieldLabel>)
      }

      {
        trips?.status === 'failed' && (
          <FormFieldLabel testId="errorTripsLabel">{customer.error}</FormFieldLabel>)
      }

      <Spinner fullPage busy={trips.status === 'loading'} testId='tripHistorySearchSpinner' />
      <Modal
        shown={customer?.errorDialog?.code !== undefined}
        onClose={handleConfirmErrorDialog}
        onConfirm={handleConfirmErrorDialog}
        buttonConfirmLabel="Ok"
        buttonConfirmText="Ok"
        title={customer?.errorDialog?.title}
        status='error'
        testId='confirmErrorModal'
      >
        <p>{customer?.errorDialog?.message}</p>
      </Modal>
    </>
  );
}
