import { ICustomerTripBilling } from "../../../models/customer-data.interface";

export const TripPaymentState = {
    PAID: 'Paid',
    UNPAID: 'Unpaid',
    PRE_AUTH: 'PreAuth',
    WAIT_FOR_PRE_AUTH: 'Wait for PreAuth',
    REJECTED: 'Rejected',
    CANCELED: 'Canceled',
}

const TripPaymentStateKeys = Object.keys(TripPaymentState);
const TripPaymentStateValues = Object.values(TripPaymentState);

export type FilterPaymentState = keyof (typeof TripPaymentState) | 'ALL'

export function getPaymentState(tripState: "UPCOMING" | "VEHICLE_READY" | "IN_TRIP" | "IN_STOPOVER" | "COMPLETED" | "CANCELED", items: ICustomerTripBilling[]) {
    switch (tripState) {
        case 'CANCELED':
            return items.find(i => i.state === 'COMPLETED') ? TripPaymentState.PAID :
                (items.find(i => i.state === 'AUTHORIZATION_HOLD') ? TripPaymentState.PRE_AUTH :
                    (items.find(i => i.state === 'REJECTED') ? TripPaymentState.REJECTED : TripPaymentState.CANCELED));
        case "UPCOMING":
        case "VEHICLE_READY":
        case "IN_TRIP":
        case "IN_STOPOVER":
            return items.find(i => i.state === 'AWAITING_AUTHORIZATION') ? TripPaymentState.WAIT_FOR_PRE_AUTH :
                (items.find(i => i.state === 'AUTHORIZATION_HOLD') ? TripPaymentState.PRE_AUTH :
                    (items.find(i => i.state === 'REJECTED') ? TripPaymentState.REJECTED : TripPaymentState.CANCELED))
        case "COMPLETED":
            return items.find(i => i.state !== 'COMPLETED') ? TripPaymentState.UNPAID : TripPaymentState.PAID;
    }
}

export function getFilterPaymentState(tripState: "UPCOMING" | "VEHICLE_READY" | "IN_TRIP" | "IN_STOPOVER" | "COMPLETED" | "CANCELED", items: ICustomerTripBilling[]): FilterPaymentState {
    const state = getPaymentState(tripState, items);

    const stateIndex = TripPaymentStateValues.findIndex(s => s === state);
    return TripPaymentStateKeys[stateIndex] as FilterPaymentState;
}