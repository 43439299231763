import React from 'react'
import './style.css';
import { Icon, Modal, Table } from '@vwfs-bronson/bronson-react'
import { Amount, ICustomerTripBilling } from '../../../models/customer-data.interface'
import { formatter } from '../../../utils/time-utils';

interface InvoicesModalInterface {
  invoices: ICustomerTripBilling[],
  showModal: boolean,
  handleOnClose: () => void
}

export default function InvoicesModal({ invoices, showModal, handleOnClose }: InvoicesModalInterface) {
  const renderInvoiceTable = () => {
    function createAmountString(amount: Amount) {
      return amount && amount.grossAmount && amount.currency ? `${amount.grossAmount} ${amount.currency}` : "-";
    }

    return (
      <Table colored={true} testId="test-invoices-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Type</Table.Th>
            <Table.Th>Invoice number</Table.Th>
            <Table.Th>State</Table.Th>
            <Table.Th>Time</Table.Th>
            <Table.Th>Amount</Table.Th>
            <Table.Th>PDF</Table.Th>
          </Table.Tr>
          {
            invoices.map(invoice => {

              const amountString = invoice.type === 'REFUND' ? createAmountString(invoice.refundAmount) : createAmountString(invoice.totalAmount)

              return (<Table.Tr key={invoice.invoiceNumber} className="table-row">
                <Table.Td>{invoice.type}</Table.Td>
                <Table.Td >{invoice.invoiceNumber}</Table.Td>
                <Table.Td>{invoice.state}</Table.Td>
                <Table.Td>{invoice.timestamp ? formatter.format(new Date(invoice.timestamp)) : '-'}</Table.Td>
                <Table.Td>{amountString}</Table.Td>
                <Table.Td>
                  {
                    invoice.invoicePDFURL && (
                      <a href={invoice.invoicePDFURL} target='_blank' rel="noreferrer" >
                        <Icon className="c-badge__icon c-icon--[semantic-download] semantic-download c-icon--small" />
                      </a>
                    )
                  }
                </Table.Td>
              </Table.Tr>)
            })
          }
        </Table.Thead>
      </Table>
    )
  }

  return <Modal
    shown={showModal}
    onClose={handleOnClose}
    onConfirm={handleOnClose}
    buttonConfirmLabel="Ok"
    buttonConfirmText="Ok"
    title={`Invoice`}
    large={true}
    status='info'
    testId='test-invoices-table-modal'>
    {renderInvoiceTable()}
  </Modal>
}
