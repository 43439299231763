import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  markCustomerForDeletion,
  unmarkCustomerForDeletion,
  fetchCustomerDataById,
  selectCustomer, suspendCustomer, unsuspendCustomer,
} from "../../../redux/slices/customer-slice";
import DetailComponent from "../../base/detail-component";
import { BookingStatusEnum, TripStatusEnum } from '../../../models/customer-data.interface';

export default function PersonalDataView() {
  const customer = useAppSelector(selectCustomer());
  const dispatch = useAppDispatch();
  const [isMarkForDeletionModalOpen, setIsMarkForDeletionModalOpen] = useState(false);
  const [isUnmarkForDeletionModalOpen, setIsUnmarkForDeletionModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isUnsuspendModalOpen, setIsUnsuspendModalOpen] = useState(false);
  const [isCustomerSuspendable, setIsCustomerSuspendable] = useState(true);

  const handleUnmarkCustomerForDeletionClick = () => {
    setIsUnmarkForDeletionModalOpen(true);
  };

  const handleConfirmUnmarkForDeletion = async () => {
    setIsUnmarkForDeletionModalOpen(false);
    dispatch(unmarkCustomerForDeletion({ id: customer.customer?.id })).then((res) => {
      if ('fulfilled' === res.meta.requestStatus) {
        dispatch(fetchCustomerDataById({ id: customer.customer?.id }));
      }
    });
  };

  const handleCancelUnmarkForDeletion = () => {
    setIsMarkForDeletionModalOpen(false);
  };

  const handleMarkCustomerForDeletionClick = () => {
    setIsMarkForDeletionModalOpen(true);
  };

  const handleConfirmMarkForDeletion = async () => {
    setIsMarkForDeletionModalOpen(false);
    dispatch(markCustomerForDeletion({ id: customer.customer?.id })).then((res) => {

      if ('fulfilled' === res.meta.requestStatus && !res.payload) {
        dispatch(fetchCustomerDataById({ id: customer.customer?.id }));
      }
    });
  };

  const handleCancelMarkForDeletion = () => {
    setIsMarkForDeletionModalOpen(false);
  };

  const handleSuspendClick = () => {
    setIsSuspendModalOpen(true)
  }

  const handleConfirmSuspend = async () => {
    setIsSuspendModalOpen(false);
    dispatch(suspendCustomer({ id: customer.customer?.id })).then((res) => {
      if ('fulfilled' === res.meta.requestStatus && res.payload === '') {
        dispatch(fetchCustomerDataById({ id: customer.customer?.id }));
      }
    });
  };

  const handleCancelSuspend = () => {
    setIsSuspendModalOpen(false);
  };

  const handleUnsuspendClick = () => {
    setIsUnsuspendModalOpen(true)
  }

  const handleConfirmUnsuspend = async () => {
    setIsUnsuspendModalOpen(false);

    dispatch(unsuspendCustomer({ id: customer.customer?.id })).then((res) => {
      if ('fulfilled' === res.meta.requestStatus) {
        dispatch(fetchCustomerDataById({ id: customer.customer?.id }));
      }
    });
  };

  const handleCancelUnsuspend = () => {
    setIsUnsuspendModalOpen(false);
  };

  const handleConfirmErrorDialog = () => {
    dispatch(fetchCustomerDataById({ id: customer.customer?.id }));
  };

  useEffect(() => {
    let customerSuspendable = true;

    if (customer?.trips?.data?.length > 0) {
      const inTripStates: TripStatusEnum[] = ['IN_STOPOVER', 'IN_TRIP', 'UPCOMING', 'VEHICLE_READY'];
      const index = customer.trips.data.findIndex(trip => inTripStates.includes(trip.state));

      if (index !== -1) {
        customerSuspendable = false;
      }
    }

    if (customer?.bookings?.data?.length > 0) {
      const hasBookingStates: BookingStatusEnum[] = ['NO_SHOW', 'REQUEST_CONFIRMED', 'REQUEST_RECEIVED', 'RESERVED', 'PICKED_UP'];
      const index = customer.bookings.data.findIndex(booking => hasBookingStates.includes(booking.status));

      if (index !== -1) {
        customerSuspendable = false;
      }
    }

    setIsCustomerSuspendable(customerSuspendable);
  }, [customer, customer.trips, customer.bookings]);

  return (
    <>
      <div>
        <div className='personal-details'>
          <DetailComponent id='customerId' label='Customer ID' value={customer.customer?.id} copyIcon />
          <DetailComponent id='firstName' label='First Name' value={customer.customer?.firstName} />
          <DetailComponent id='lastName' label='Last Name' value={customer.customer?.lastName} />
          <DetailComponent id='language' label='Language' value={customer.customer?.language} />
          <DetailComponent id='dateOfBirth' label='Date of Birth' value={customer.customer?.dateOfBirth} />
        </div>
        <div className='personal-details'>
          <DetailComponent id='email' label='E-Mail' value={customer.customer?.email} copyIcon />
          <DetailComponent id='phoneNumber' label='Phone Number' value={customer.customer?.phoneNumber} copyIcon />
        </div>
        <div className='personal-details'>
          <DetailComponent id='countryCode' label='Country Code' value={customer.customer?.address?.countryCode} />
          <DetailComponent id='street' label='Street' value={customer.customer?.address?.street} />
          <DetailComponent id='houseNumber' label='House Number' value={customer.customer?.address?.houseNumber} />
          <DetailComponent id='zipCode' label='ZIP Code' value={customer.customer?.address?.zipCode} />
          <DetailComponent id='city' label='City' value={customer.customer?.address?.city} />
        </div>
        <div className='personal-details'>
          <DetailComponent id='accountStatus' label='Account Status' value={customer.customer?.accountStatus} />
          <DetailComponent id='personalDetailsStatus' label='Personal Details Status'
            value={customer.customer?.personalDetailsStatus} />
          <DetailComponent id='phoneNumberVerificationStatus' label='Phone Number Verification Status'
            value={customer.customer?.phoneNumberVerificationStatus} />
          <DetailComponent id='deletionRequestDate' label='Deletion Request Date'
            value={customer.customer?.deletionRequestDate} />
        </div>
        <div className='personal-details'>
          <DetailComponent id='createdAt' label='Created At' value={customer.customer?.createdAt} />
          <DetailComponent id='updatedAt' label='Updated At' value={customer.customer?.updatedAt} />
        </div>

        <div>
          <Button
            className='blue-button'
            data-testid='unsuspendButtonTestID'
            onClick={handleUnsuspendClick}
            disabled={customer.customer?.accountStatus !== "SUSPENDED"}
            title={(customer.customer?.accountStatus !== "SUSPENDED" ? 'Only possible for suspended customers.' : '')}
          >
            UNSUSPEND
          </Button>
          <Button
            className='blue-button'
            data-testid='suspendButtonTestID'
            onClick={handleSuspendClick}
            disabled={customer.customer?.accountStatus !== "APPROVED" || !isCustomerSuspendable}
            title={(customer.customer?.accountStatus !== "APPROVED" || !isCustomerSuspendable ? 'Only possible for approved customers that are not on a Ride.' : '')}
          >
            SUSPEND
          </Button>
          <Button
            className='blue-button'
            data-testid='markForDeletionButtonTestID'
            onClick={handleMarkCustomerForDeletionClick}
            disabled={customer.customer?.accountStatus !== "SUSPENDED" || !customer.customer?.deletionRequestDate}
            title={((customer.customer?.accountStatus !== "SUSPENDED" || !customer.customer?.deletionRequestDate) ? 'Only possible for customers who have requested account deletion via the app.' : '')}
          >
            MARK FOR DELETION
          </Button>

          <Button
            className='blue-button'
            data-testid='unmarkForDeletionButtonTestID'
            onClick={handleUnmarkCustomerForDeletionClick}
            disabled={customer.customer?.accountStatus !== "MARKED_FOR_DELETION"}
            title={((customer.customer?.accountStatus !== "MARKED_FOR_DELETION") ? 'Only possible for customers who are marked for deletion.' : '')}
          >
            UNMARK FOR DELETION
          </Button>
        </div>
      </div>
      <Modal
        shown={isMarkForDeletionModalOpen}
        onClose={handleCancelMarkForDeletion}
        onConfirm={handleConfirmMarkForDeletion}
        onCancel={handleCancelMarkForDeletion}
        buttonCancelLabel="Cancel"
        buttonCancelText="Cancel"
        buttonConfirmLabel="Confirm"
        buttonConfirmText="Confirm"
        title="Confirm Mark For Deletion"
      >
        <p>Are you sure you want to mark this account for deletion AND send a deletion confirmation email to the customer?</p>
      </Modal>
      <Modal
        shown={isUnmarkForDeletionModalOpen}
        onClose={handleCancelUnmarkForDeletion}
        onConfirm={handleConfirmUnmarkForDeletion}
        onCancel={handleCancelUnmarkForDeletion}
        buttonCancelLabel="Cancel"
        buttonCancelText="Cancel"
        buttonConfirmLabel="Confirm"
        buttonConfirmText="Confirm"
        title="Confirm Unmark For Deletion"
      >
        <p>Are you sure you want to unmark this account for deletion?<br />This will make the account ACTIVE|IN_PROGRESS so please make sure if account should be SUSPENDED</p>
      </Modal>
      <Modal
        shown={isSuspendModalOpen}
        onClose={handleCancelSuspend}
        onConfirm={handleConfirmSuspend}
        onCancel={handleCancelSuspend}
        buttonCancelLabel="Cancel"
        buttonCancelText="Cancel"
        buttonConfirmLabel="Confirm"
        buttonConfirmText="Confirm"
        title="Confirm Suspend Account"
      >
        <p>Are you sure you want to suspend this account?</p>
      </Modal>
      <Modal
        shown={isUnsuspendModalOpen}
        onClose={handleCancelUnsuspend}
        onConfirm={handleConfirmUnsuspend}
        onCancel={handleCancelUnsuspend}
        buttonCancelLabel="Cancel"
        buttonCancelText="Cancel"
        buttonConfirmLabel="Confirm"
        buttonConfirmText="Confirm"
        title="Confirm Unsuspend Account"
      >
        <p>Are you sure you want to unsuspend this account?</p>
      </Modal>
      <Modal
        shown={customer.errorDialog?.code !== undefined}
        onClose={handleConfirmErrorDialog}
        onConfirm={handleConfirmErrorDialog}
        buttonConfirmLabel="Ok"
        buttonConfirmText="Ok"
        title={customer.errorDialog?.title}
        status='error'
        testId='confirmSuspensionErrorModal'
      >
        <p>{customer.errorDialog?.message}</p>
      </Modal>
    </>
  );
}
