import { clearAuthParams } from '../utils/auth-utils';

export default {
    authority: `${process.env.REACT_APP_EMOBG_AUTH_AUTHORITY_URL}`,
    metadataUrl: `${process.env.REACT_APP_EMOBG_AUTH_METADATA_URL}`,
    client_id: 'mma-backoffice',
    redirect_uri: window.location.origin ,
    response_type: 'code',
    onSigninCallback: clearAuthParams,
    post_logout_redirect_uri: window.location.origin
};
