import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ICustomerTrip, ICustomerTripsResponse } from '../../models/customer-data.interface';
import { RootState } from '../store';
import * as customerSupportApi from '../../apis/customer-support-api';
import { CustomerState } from './customer-state';
import { Action } from '../types';
import { FilterByDateRange } from './types';
import { FilterPaymentState, getFilterPaymentState } from '../../components/details-view/trip-history/helper';

export const selectTrips = () => (state: RootState) => {
    return state.customer.trips;
};

// ## Fetch Customer Trips
const thunkHandler = async ({ id }) => {
    const result: ICustomerTripsResponse = await customerSupportApi.getCustomerTrips(id);
    return result.data;
    //return result.data.sort((e1, e2) => {
    //    return new Date(e2.tripPeriod?.startTimestamp || 0).valueOf() - new Date(e1.tripPeriod?.startTimestamp || 0).valueOf()
    //});
}

const thunkOptions = {
    condition: (refresh, { getState }) => {
        // execute the function if it's explicitly requested
        if (refresh) {
            return true;
        }

        const { customer } = getState();
        customer.error = null;
        return customer.trips.status === 'idle'; // execute the fetch function only when the status is idle
    },
}

export const fetchCustomerTrips = createAsyncThunk<ICustomerTrip[], { id: string }, { state: RootState }>('customer/fetchCustomerTrips', thunkHandler, thunkOptions);


export function addTripsCases(builder: ActionReducerMapBuilder<CustomerState>) {
    builder
        .addCase(fetchCustomerTrips.pending, (state, action) => {
            state.trips.status = 'loading';
            state.error = null;
            state.errorDialog = null;
        })
        .addCase(fetchCustomerTrips.fulfilled, (state, action) => {
            state.error = null;
            state.errorDialog = null;
            state.trips.data = state.trips.presentationData = state.trips.filteredData = action.payload;
            state.trips.status = 'succeeded';
            state.trips.hasOpenPayments = action.payload.findIndex(data => data.state !== 'COMPLETED') !== -1
        })
        .addCase(fetchCustomerTrips.rejected, (state, action) => {
            state.error = 'An error occurred: Could not fetch the trips of the customer.';
            state.errorDialog = {
                code: 'UNABLE_TO_FETCH_TRIPS',
                title: 'Unable to fetch Trips',
                message: 'An error occurred: Could not fetch the trips of the customer.'
            }
            state.id = undefined;
            state.trips.status = 'failed';
            state.trips.hasOpenPayments = null
        })
}

export const tripsReducers = {
    setTripsPaymentState(state: CustomerState, action: Action<FilterPaymentState>) {
        const paymentState = action.payload
        state.trips.filter.paymentState = paymentState;

        if (paymentState === 'ALL') {
            state.trips.presentationData = state.trips.filteredData;
            return
        }

        const filteredTrips = state.trips.filteredData.filter(item => {
            const state = getFilterPaymentState(item.state, item.billings);
            return state === paymentState
        })

        state.trips.presentationData = filteredTrips;
    },
    setTripsDateRageFilter(state: CustomerState, action: Action<FilterByDateRange>) {
        const dateRange = action.payload
        state.trips.filter.dateRange = dateRange;
        state.trips.filter.paymentState = 'ALL';

        if (!dateRange) {
            state.trips.presentationData = state.trips.data;
            state.trips.filteredData = state.trips.data;
            return
        }

        const startTimestamp = new Date(dateRange.start).getTime();
        const stopTimestamp = new Date(dateRange.stop).getTime();

        const filteredTrips = state.trips.data.filter(item =>
            item.tripPeriod && (
                new Date(item.tripPeriod.startTimestamp).getTime() >= startTimestamp &&
                new Date(item.tripPeriod.endTimestamp).getTime() <= stopTimestamp
            ))

        state.trips.presentationData = filteredTrips;
        state.trips.filteredData = filteredTrips;
    },
}
