import React from 'react';
import { FormFieldLabel, InfoIcon, Spinner, Table } from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppSelector } from "../../../redux/hooks";
import { selectAuditTrail } from "../../../redux/slices/customer-slice";
import ICustomerData, {
  Address,
  ConsentData,
  IAuditCustomer, MspStatusList,
} from "../../../models/customer-data.interface";
import { ImageIcon } from "../../base/image-icon/ImageIcon";
import {
  isAddressDataEqual,
  isConsentDataEqual,
  isMspDataEqual,
  isPersonalDataEqual,
  containsConsent,
  containsMsp
} from "../../../utils/DiffUtils";
import { formatter } from '../../../utils/time-utils';


export default function AuditTrailDataView() {
  const auditTrail = useAppSelector(selectAuditTrail());

  function renderMspStatus(mspStatus: MspStatusList, lastMspStatus: MspStatusList, isLastEntry: boolean) {
    const isEqual = isMspDataEqual(mspStatus, lastMspStatus) || isLastEntry
    let shortText = '';
    const tooltip = [];
    mspStatus?.map(item => {
      tooltip.push(<div key={item.serviceProviderId}><span className={containsMsp(item, lastMspStatus) || isLastEntry ? "noWrap" : "noWrap mark-for-changed"}>{item.serviceProviderId + '(' + item.status + ')'}</span><br /></div>);
      shortText += (shortText != '' ? ', ' : '') + item.serviceProviderId;
    })
    if (shortText === '') {
      return <div className={isEqual ? "noWrap" : "noWrap mark-for-changed"}>---</div>
    }
    return (<div className={isEqual ? "noWrap" : "noWrap mark-for-changed"}>{shortText} <InfoIcon className="semantic-info" testId="infoIconMspData">
      {tooltip.map((line) => line)}
    </InfoIcon></div>
    );
  }

  function renderConsentData(consentData: ConsentData[], lastConsentData: ConsentData[], isLastEntry: boolean) {
    const isEqual = isConsentDataEqual(consentData, lastConsentData) || isLastEntry
    let shortText = '';
    const tooltip = [];
    consentData?.map(item => {
      if (item.isGiven) {
        tooltip.push(<div key={item.consentType}><span className={containsConsent(item, lastConsentData) || isLastEntry ? "noWrap" : "noWrap mark-for-changed"}>{item.consentType + '(' + item.date + (item.version ? ',' + item.version : '') + ')'}</span><br /></div>);
        switch (item.consentType) {
          case "TERMS_AND_CONDITIONS": {
            shortText += (shortText != '' ? ', ' : '') + 'T&C';
            return;
          }
          case "DATA_PRIVACY": {
            shortText += (shortText != '' ? ', ' : '') + 'DP';
            return;
          }
          case "MARKETING_EC": {
            shortText += (shortText != '' ? ', ' : '') + 'MEC';
            return;
          }
          case "MARKETING_TKTM": {
            shortText += (shortText != '' ? ', ' : '') + 'MET';
            return;
          }
          case "PUSH_MESSAGES": {
            shortText += (shortText != '' ? ', ' : '') + 'PM';
            return;
          }
          default:
            shortText += (shortText != '' ? ', ' : '') + item.consentType;
            return;
        }
      }
    })
    if (shortText === '') {
      return <div className={isEqual ? "noWrap" : "noWrap mark-for-changed"}>---</div>
    }
    return (<div className={isEqual ? "noWrap" : "noWrap mark-for-changed"}>{shortText} <InfoIcon className="semantic-info" testId="infoIconConsentData">
      {tooltip.map((line) => line)}
    </InfoIcon></div>
    );
  }

  function renderStatus(status: string, lastStatus?: string, additionalText?: string,) {
    const hasChanged = status === lastStatus || !lastStatus;
    switch (status) {
      case 'APPROVED':
      case 'COMPLETED': {
        return <ImageIcon className={hasChanged ? 'semantic-success c-icon--xxsmall' : 'semantic-success c-icon--xxsmall mark-img-for-changed'} icon="semantic-success">{status}</ImageIcon>
      }
      case 'REJECTED':
      case 'SUSPENDED': {
        return <ImageIcon className={hasChanged ? 'semantic-close c-icon--xxsmall' : 'semantic-close mark-img-for-changed c-icon--xxsmall'} icon="semantic-close">{status}</ImageIcon>
      }
      case 'EXPIRED':
      case 'MARKED_FOR_DELETION': {
        return <ImageIcon className={hasChanged ? 'semantic-delete c-icon--xxsmall' : 'semantic-delete mark-img-for-changed c-icon--xxsmall'} icon="semantic-delete">{status + (additionalText ? ' (' + additionalText + ')' : '')}</ImageIcon>
      }
      case 'INIT':
      case 'PENDING_APPROVAL':
      case 'PENDING':
      case 'AWAITING_AUTHORIZATION': {
        return <ImageIcon className={hasChanged ? 'semantic-clock c-icon--xxsmall' : 'semantic-clock mark-img-for-changed c-icon--xxsmall'} icon="semantic-clock">{status}</ImageIcon>
      }
      case 'SKIPPED': {
        return <ImageIcon className={hasChanged ? 'semantic-pause c-icon--xxsmall' : 'semantic-pause mark-img-for-changed c-icon--xxsmall'} icon="semantic-pause">{status}</ImageIcon>
      }
      case 'IN_PROGRESS': {
        return <ImageIcon className={hasChanged ? 'semantic-performance c-icon--xxsmall' : 'semantic-performance mark-img-for-changed c-icon--xxsmall'} icon="semantic-performance">{status}</ImageIcon>
      }
      default:
        return <ImageIcon className={hasChanged ? 'semantic-error c-icon--xxsmall' : 'semantic-error mark-img-for-changed c-icon--xxsmall'} icon="semantic-error">{status}</ImageIcon>
    }
  }

  function renderPersonalData(current: ICustomerData, last: ICustomerData, isLastEntry: boolean) {
    const isEqual = isPersonalDataEqual(current, last) || isLastEntry;
    const nameEqual = (current?.firstName === last?.firstName || !last?.firstName) && (current?.lastName === last?.lastName || isLastEntry);

    return <div>{(current?.firstName || current?.lastName) && (<span className={isEqual ? '' : 'mark-for-changed'}>{current?.firstName} {current?.lastName}</span>)}
      {(!current?.firstName && !current?.lastName) && (<span className={isEqual ? '' : 'mark-for-changed'}>---</span>)}
      <InfoIcon className="semantic-info" testId="infoIconPersonalData">
        <span className={nameEqual ? "noWrap" : "noWrap mark-for-changed"}>{current?.firstName} {current?.lastName}</span><br />
        <span className={(current?.language === last?.language || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>Language: {current?.language}</span><br />
        <span className={(current?.dateOfBirth === last?.dateOfBirth || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>Date of Birth: {current?.dateOfBirth}</span><br />
        <span className={(current?.email === last?.email || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>Email: {current?.email}</span><br />
        <span className={(current?.phoneNumber === last?.phoneNumber || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>Phone Nr: {current?.phoneNumber}</span><br />
      </InfoIcon>
    </div>
  }

  function renderAddressData(current: Address, last: Address, isLastEntry: boolean) {
    const isEqual = isAddressDataEqual(current, last) || isLastEntry;
    if (!current?.street && !current?.houseNumber && !current?.city) {
      return <div>---</div>
    }
    return <div><span className={isEqual ? '' : 'mark-for-changed'}>{current?.street + (current?.houseNumber ? ' ' + current?.houseNumber : '') + ', ' + current?.city}</span>
      {(current?.street || current?.houseNumber || current?.city) && (
        <InfoIcon className="semantic-info" testId="infoIconAddressData">
          <span className={(current?.street === last?.street || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>Street: {current?.street}</span><br />
          <span className={(current?.houseNumber === last?.houseNumber || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>House Number: {current?.houseNumber}</span><br />
          <span className={(current?.zipCode === last?.zipCode || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>Zip Code: {current?.zipCode}</span><br />
          <span className={(current?.city === last?.city || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>City: {current?.city}</span><br />
          <span className={(current?.countryCode === last?.countryCode || isLastEntry) ? "noWrap" : "noWrap mark-for-changed"}>Country Code: {current?.countryCode}</span><br />
        </InfoIcon>
      )}
    </div>
  }

  function renderItem(auditTrail: IAuditCustomer, lastAuditTrail: IAuditCustomer, isLastEntry: boolean) {

    return (
      <Table.Tr key={auditTrail.auditId} >
        <Table.Td>{formatter.format(new Date(auditTrail.auditTimestamp))} <InfoIcon className="semantic-info" testId='infoIconTimestamp'>{auditTrail.auditTimestamp}</InfoIcon>
        </Table.Td>
        <Table.Td>
          {auditTrail.auditChangedBy.startsWith('ChangedByCustomer') ? 'Customer ' : 'Support '}
          <InfoIcon className="semantic-info" testId='infoIconChangeBy'>{auditTrail.auditChangedBy}</InfoIcon>
        </Table.Td>
        <Table.Td>{renderStatus(auditTrail.customer?.accountStatus?.toString(), lastAuditTrail?.customer?.accountStatus?.toString(), auditTrail.customer?.deletionRequestDate)}</Table.Td>
        <Table.Td>{!auditTrail.customer?.personalDetailsStatus ? '' : renderStatus(auditTrail.customer?.personalDetailsStatus?.toString(), lastAuditTrail?.customer?.personalDetailsStatus?.toString())}</Table.Td>
        <Table.Td>{!auditTrail.customer?.phoneNumberVerificationStatus ? '' : renderStatus(auditTrail.customer?.phoneNumberVerificationStatus?.toString(), lastAuditTrail?.customer?.phoneNumberVerificationStatus?.toString())}</Table.Td>
        <Table.Td>{!auditTrail.customer?.idVerificationStatus ? '' : renderStatus(auditTrail.customer?.idVerificationStatus?.toString(), lastAuditTrail?.customer?.idVerificationStatus?.toString())}</Table.Td>
        <Table.Td>{!auditTrail.customer?.dlVerificationStatus ? '' : renderStatus(auditTrail.customer?.dlVerificationStatus?.toString(), lastAuditTrail?.customer?.dlVerificationStatus?.toString())}</Table.Td>
        <Table.Td>{!auditTrail.customer?.paymentMethodStatus ? '' : renderStatus(auditTrail.customer?.paymentMethodStatus?.toString(), lastAuditTrail?.customer?.paymentMethodStatus?.toString())}</Table.Td>
        <Table.Td>{renderPersonalData(auditTrail?.customer, lastAuditTrail?.customer, isLastEntry)}</Table.Td>
        <Table.Td>{renderAddressData(auditTrail?.customer?.address, lastAuditTrail?.customer?.address, isLastEntry)}</Table.Td>
        <Table.Td>{renderConsentData(auditTrail?.customer?.consentData, lastAuditTrail?.customer?.consentData, isLastEntry)}</Table.Td>
        <Table.Td>{renderMspStatus(auditTrail.customer?.mspStatus, lastAuditTrail?.customer?.mspStatus, isLastEntry)}</Table.Td>
      </Table.Tr>
    );
  }

  function renderAuditCustomer(data: IAuditCustomer[]) {
    let index = 1;
    return data.map(item => renderItem(item, data[index], data?.length === index++));
  }

  return (
    <div data-testid="tableComponent" key="auditTrailTableDiv">
      {auditTrail.data && auditTrail.data?.length > 0 && (
        <Table colored={true} className="scrollable-table">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>AuditTimestamp</Table.Th>
              <Table.Th>Changed By</Table.Th>
              <Table.Th>AS<InfoIcon className="semantic-info">Account Status</InfoIcon></Table.Th>
              <Table.Th>PD<InfoIcon className="semantic-info">Personal Details Status</InfoIcon></Table.Th>
              <Table.Th>PN<InfoIcon className="semantic-info">Phone Number Verification Status</InfoIcon></Table.Th>
              <Table.Th>Id<InfoIcon className="semantic-info">Id Verification Status</InfoIcon></Table.Th>
              <Table.Th>Dl<InfoIcon className="semantic-info">Dl Verification Status</InfoIcon></Table.Th>
              <Table.Th>PM<InfoIcon className="semantic-info">Payment Method Status</InfoIcon></Table.Th>
              <Table.Th>Personal Data</Table.Th>
              <Table.Th>Address</Table.Th>
              <Table.Th>Consent Data</Table.Th>
              <Table.Th>MSP Data</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{renderAuditCustomer(auditTrail.data)}</Table.Tbody>
        </Table>
      )}
      {(!auditTrail.data || auditTrail.data?.length === 0) && (<FormFieldLabel testId="noAuditTrailLabel">No Audit Trail for Customer</FormFieldLabel>)}
      <Spinner fullPage busy={auditTrail.status == 'loading'} testId='auditTrailSearchSpinner' />
    </div>
  );
}
