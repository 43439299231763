import React from 'react';
import { FormFieldLabel, Table } from '@vwfs-bronson/bronson-react';
import { ConsentData, MspStatus } from '../../../models/customer-data.interface';
import { useAppSelector } from "../../../redux/hooks";
import { selectCustomer } from "../../../redux/slices/customer-slice";
import './style.css';


export default function AgreementsDataView() {
  const customer = useAppSelector(selectCustomer());

  function renderConsentItem(cd: ConsentData) {
    return (
      <Table.Tr key={cd.consentType} className='table-row'>
        <Table.Td>{cd.consentType}</Table.Td>
        <Table.Td>{cd.isGiven.toString()}</Table.Td>
        <Table.Td>{cd.version}</Table.Td>
        <Table.Td>{cd.date}</Table.Td>
      </Table.Tr>
    );
  }

  function renderMspItem(cd: MspStatus) {
    return (
      <Table.Tr key={cd.serviceProviderId} className='table-row'>
        <Table.Td>{cd.serviceProviderId}</Table.Td>
        <Table.Td>{cd.status}</Table.Td>
      </Table.Tr>
    );
  }

  return (
    <>
      <div className='agreements-details' data-testid="consentDataTableComponent">
        {customer.customer?.consentData && customer.customer?.consentData?.length > 0 && (
          <>
            <p className='label'>Consent Data</p>
            <Table colored={true}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Type</Table.Th>
                  <Table.Th>Is Given</Table.Th>
                  <Table.Th>Version</Table.Th>
                  <Table.Th>Date</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{customer.customer?.consentData?.map((cd) => renderConsentItem(cd))}</Table.Tbody>
            </Table>
          </>
        )}

        {(!customer.customer?.consentData || customer.customer?.consentData?.length === 0) && (<FormFieldLabel testId="noConsentDataLabel">No Consent Data</FormFieldLabel>)}

      </div>
      <div className='agreements-details' data-testid="mspDataTableComponent">
        {customer.customer?.mspStatus && customer.customer?.mspStatus?.length > 0 && (
          <>
            <p className='label'>MSP Data</p>
            <Table colored={true} >
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Name</Table.Th>
                  <Table.Th>Status</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{customer.customer?.mspStatus?.map((msp) => renderMspItem(msp))}</Table.Tbody>
            </Table>
          </>
        )}

        {(!customer.customer?.mspStatus || customer.customer?.mspStatus?.length === 0) && (<FormFieldLabel testId="noConsentDataLabel">No MSP Data</FormFieldLabel>)}
      </div>
    </>
  );
}
