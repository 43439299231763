import React, { useState } from 'react';
import { Button, FormFieldLabel, InfoIcon, Modal, Spinner, Table, Tooltip } from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetErrorDialog, setErrorDialog, selectBookings, selectCustomer } from "../../../redux/slices/customer-slice";
import { cancelCustomerBooking } from "../../../redux/slices/customer-slice";
import {
  BookingAdditionalProduct,
  BookingStatusEnum,
  ICustomerBooking,
} from "../../../models/customer-data.interface";
import MessageModal from '../../base/message-modal';
import { formatter } from '../../../utils/time-utils';

function generateBookingStatusDescriptionInfoIcon() {
  return <InfoIcon className="semantic-info" testId="infoIconBookingStatusDescription">
    <p><b>Received</b>:Request for booking received bot not confirmed.</p>
    <p><b>Confirmed</b>:Received booking request successfully confirmed.</p>
    <p><b>Reserved</b>:Booking request successfully reserved.</p>
    <p><b>Postpaid canceled</b>:Booking canceled after it&apos;s paid.</p>
    <p><b>Prepaid canceled</b>:Booking canceled before it&apos;s paid.</p>
    <p><b>Rejected</b>:Booking reservation rejected.</p>
  </InfoIcon>;
}

function generatePaymentStatusDescriptionInfoIcon() {
  return <InfoIcon className="semantic-info" testId="infoIconPaymentStatusDescription">
    <p><b>Prepayment (Wait for 3DS)</b>:Awaiting for payment to be verified.</p>
    <p><b>Prepayment (In Progress)</b>:Payment verified with 3DS, but not captured.</p>
    <p><b>Prepayment (Paid)</b>:Payment successfully captured.</p>
    <p><b>Prepayment (Rejected)</b>:Payment capture rejected.</p>
    <p><b>Prepayment (Canceled)</b>:Payment authorization canceled.</p>
    <p><b>Prepayment (Refunded)</b>:Payment refunded.</p>
    <p><b>Prepayment (Refunded rejected)</b>:Payment refund rejected.</p>
  </InfoIcon>;
}

export default function BookingHistoryDataView() {
  let keys: string[];

  const [showModal, setShowModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);

  const customer = useAppSelector(selectCustomer());
  const bookings = useAppSelector(selectBookings());
  const dispatch = useAppDispatch();

  const handleOnClose = () => {
    setSelectedBookingId(null);
    setShowModal(false);
  }

  const handleOnModalShow = (bookingId) => {
    setShowModal(true);
    setSelectedBookingId(bookingId);
  }

  const handleCancelBooking = () => {
    handleOnClose();

    if (!customer.id || !selectedBookingId) {
      dispatch(setErrorDialog({
        title: 'Unable to cancel booking',
        message: 'Customer booking cannot be canceled, some data is missing.'
      }));
      return
    }

    dispatch(cancelCustomerBooking({
      customerId: customer.id,
      bookingId: selectedBookingId
    }))
  }

  const handleConfirmErrorDialog = () => {
    dispatch(resetErrorDialog(undefined));
  };

  function renderPaymentState(booking: ICustomerBooking) {
    if (!booking) return null;

    const { paymentState } = booking;

    switch (paymentState) {
      case 'AUTHORIZATION_HOLD':
        return 'Prepayment (In Progress)'
      case 'AWAITING_AUTHORIZATION':
        return `Prepayment (Wait for 3DS)`
      case 'COMPLETED':
        return `Prepayment (Paid)`
      case 'CANCELED':
        return `Prepayment (Canceled)`
      case 'REJECTED':
        return `Prepayment (Rejected)`
      case 'REFUNDED':
        return `Prepayment (Refunded)`
      case 'REFUND_REJECTED':
        return `Prepayment (Refunded rejected)`
      default:
        return 'PayOnArrival'
    }
  }

  function renderBookingStatus(booking: ICustomerBooking) {
    if (!booking) return null;

    const { status } = booking;

    let statusText = "-";

    switch (status) {
      case 'REQUEST_CONFIRMED':
        statusText = 'Confirmed';
        break;
      case 'REQUEST_RECEIVED':
        statusText = `Received`;
        break;
      case 'RESERVED':
        statusText = `Reserved`;
        break;
      case 'POSTPAID_CANCELED':
        statusText = `Postpaid canceled`;
        break;
      case 'PREPAID_CANCELLED':
        statusText = `Prepaid canceled`;
        break;
      case 'REJECTED':
        statusText = `Rejected`;
        break
    }

    if (booking?.updateDateTime) {
      const { updateDateTime } = booking;

      const dateString = updateDateTime ? `${formatter.format(new Date(updateDateTime))}` : '';
      return <Tooltip content={`${dateString}`}>{`${statusText}`}</Tooltip>;
    }

    return statusText;
  }

  function renderAdditionalProduct(product: BookingAdditionalProduct) {
    const prods: string[] = [];

    product.choices?.map(choice => {
      if ((choice.isSelected || choice.includedInPackage) && !keys.includes(choice.code)) {
        keys.push(choice.code);
        prods.push(choice.name);
      }
    })
    return prods.map(prod => <li key={prod} style={{ margin: '0px' }}>{prod}</li>);
  }

  function renderCancelButton(booking: ICustomerBooking) {
    const cancelableStatuses: BookingStatusEnum[] = ['RESERVED', 'REQUEST_CONFIRMED', 'REQUEST_RECEIVED'];
    const canceledStatuses: BookingStatusEnum[] = ['POSTPAID_CANCELED', 'PREPAID_CANCELLED'];

    const isCancelable = booking?.offer?.startDate && new Date(booking.offer.startDate) > new Date() && cancelableStatuses.includes(booking?.status);
    const isCanceled = canceledStatuses.includes(booking?.status);

    return <Button disabled={!isCancelable} onClick={() => handleOnModalShow(booking?.id)} testId={`cancel-booking-${booking?.id}-btn`}>{isCanceled ? 'canceled' : 'cancel'}</Button>;
  }

  function renderInfoIcon(booking: ICustomerBooking) {
    return <InfoIcon className="semantic-info" testId={`additional-products-${booking.id}`}>
      <ul style={{ width: '250px' }} data-testId={`additional-products-${booking.id}-popup`}>
        {booking.offer.additionalProducts.map(product => renderAdditionalProduct(product))}
      </ul>
    </InfoIcon>
  }

  function renderItem(booking: ICustomerBooking) {
    keys = [];

    const emgBookingIdentifier = booking?.order?.externalIdentifiers?.find(identfier => identfier.type === 'EUROPCAR_ORDER_ID');

    return (
      <Table.Tr key={booking?.id + '-' + booking?.status} className="table-row">
        <Table.Td>{emgBookingIdentifier?.id || 'Not Found'}</Table.Td>
        <Table.Td><img src={booking.offer.vehicleCategory.model.vehicleImages[0].url} height="50px;" /> ({booking.offer.vehicleCategory.name})</Table.Td>
        <Table.Td>{booking?.station.stationName + " (" + booking.station.address + ")"}</Table.Td>
        <Table.Td>{formatter.format(new Date(booking?.offer.startDate))}</Table.Td>
        <Table.Td>{formatter.format(new Date(booking?.offer.endDate))}</Table.Td>
        <Table.Td>{renderBookingStatus(booking)}</Table.Td>
        <Table.Td>{renderPaymentState(booking)}</Table.Td>
        <Table.Td>{renderInfoIcon(booking)}</Table.Td>
        <Table.Td>{booking?.offer.productCostAmount.grossAmount + " " + booking?.offer.productCostAmount.currency}</Table.Td>
        <Table.Td>{renderCancelButton(booking)}</Table.Td>
      </Table.Tr >
    );
  }

  return (
    <>
      {bookings.data && bookings.data?.length > 0 && (
        <Table colored={true} testId="booking-history-table">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>EMG Booking ID</Table.Th>
              <Table.Th>Category</Table.Th>
              <Table.Th>Station</Table.Th>
              <Table.Th>Pickup Date</Table.Th>
              <Table.Th>Return Date</Table.Th>
              <Table.Th>Booking Status{generateBookingStatusDescriptionInfoIcon()}</Table.Th>
              <Table.Th>Payment Status{generatePaymentStatusDescriptionInfoIcon()}</Table.Th>
              <Table.Th>Additional Products</Table.Th>
              <Table.Th>Gross Amount</Table.Th>
              <Table.Th>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{bookings?.data?.map((booking) => renderItem(booking))}</Table.Tbody>
        </Table>
      )}

      {((!bookings.data || bookings.data?.length === 0) && bookings?.status !== 'failed') && (
        <FormFieldLabel testId="noBookingsLabel">No Bookings for Customer</FormFieldLabel>)}
      {bookings?.status === 'failed' && (
        <FormFieldLabel testId="errorBookingsLabel">{customer.error}</FormFieldLabel>)}

      <Spinner fullPage busy={bookings.status === 'loading'} testId='bookingHistorySearchSpinner' />

      <Modal
        shown={customer?.errorDialog?.code !== undefined}
        onClose={handleConfirmErrorDialog}
        onConfirm={handleConfirmErrorDialog}
        buttonConfirmLabel="Ok"
        buttonConfirmText="Ok"
        title={customer?.errorDialog?.title}
        status='error'
        testId='confirmErrorModal'
      >
        <p>{customer?.errorDialog?.message}</p>
      </Modal>

      <MessageModal
        message={'Are you sure you want to cancel this booking?'}
        showModal={showModal}
        handleOnClose={handleOnClose}
        handleOnConfirm={handleCancelBooking}
        action='Yes'
        title='Cancel booking' />
    </>
  );
}
