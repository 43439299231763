import React from 'react';
import './style.css';
import {FormFieldLabel} from '@vwfs-bronson/bronson-react';
import copyPng from "../../../images/copy.png";

interface PropType {
  id: string;
  label: string;
  value?: string;
  copyIcon?: boolean;
}

export default function DetailComponent({
  id,
  label,
  value,
  copyIcon,
}: PropType) {
  function copyToClipboard() {
    console.log(value)
    navigator.clipboard.writeText(value)
  }
  return (
    <div className='details-info'>
      <FormFieldLabel testId={`${id}Label`} className="label">{label}</FormFieldLabel>
      <FormFieldLabel
        testId={`${id}Field`}>{value}{ copyIcon ? (<img src={copyPng} className="copy-icon" onClick={copyToClipboard}/>):''}</FormFieldLabel>
    </div>
  );
}
