import React from 'react';
import {Form, FormField, FormFieldLabel, Input} from '@vwfs-bronson/bronson-react'
import './style.css';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {fetchCustomerDataByIdentifier, selectCustomer, setSearchTerm, resetError} from '../../redux/slices/customer-slice';
import {Navigate} from 'react-router-dom';

export default function SearchView() {
  const dispatch = useAppDispatch();
  const [identifierValue, setIdentifierValue] = React.useState('');
  const customer = useAppSelector(selectCustomer());
  const handleChange = (e: any) => {
    dispatch(resetError(undefined));
    const searchTerm = e.target.value;
    setIdentifierValue(searchTerm)
    dispatch(setSearchTerm(searchTerm));
  }

  const navigateToDetails = (e:any) => {
    e.preventDefault()
    dispatch(fetchCustomerDataByIdentifier(true))
  };

  return (
    <Form floatingLabel testId="searchForm" onSubmit={navigateToDetails}>
        <FormField
            labelForId="test-form-input"
            labelText="Enter Email or ID of the Customer"
            type="input"
            testId='testInputText'
        >
            <Input
                addonIcon="semantic-search"
                value={identifierValue}
                id="test-email-input"
                testId='testInputField'
                placeholder="Search for Customer by Email or ID."
                noBackground
                onChange={handleChange}
                addonElement="button"
                addonClickHandler={(e) => navigateToDetails(e)}
                addonType="submit"
            />
        </FormField>
      { customer.error === 'EMAIL_NOT_FOUND' && <FormFieldLabel testId="noCustomerForEmailFoundLabel" className='Error'>Customer not found with this Email.</FormFieldLabel>}
      { customer.error === 'ID_NOT_FOUND' && <FormFieldLabel testId="noCustomerForIdFoundLabel" className='Error'>Customer not found with this ID.</FormFieldLabel>}
      { customer.error === 'FORBIDDEN' && (
        <FormFieldLabel testId="forbiddenLabel" className='Error'>
          Forbidden access.
        </FormFieldLabel>
      )}
      { customer.customer?.id && <Navigate to={`/customers/${customer.id}`} replace={true}/>}
    </Form>
  )
}
