import React, { useEffect } from 'react';
import './style.css';
import { useParams, useNavigate } from 'react-router-dom';
import {
    fetchCustomerDataById,
    fetchCustomerAuditTrail,
    resetCustomer,
    resetError,
    selectId,
    setSearchTerm, selectCustomer, resetErrorDialog,
    fetchCustomerTrips,
    fetchCustomerBookings,
    selectBookings,
    selectTrips,
    selectAuditTrail
} from '../../redux/slices/customer-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Button, Heading, LogoBanner, Spinner, Tabs } from '@vwfs-bronson/bronson-react'
import PersonalDataView from "../../components/details-view/personal-data";
import PaymentDataView from "../../components/details-view/payment-data";
import IdVerificationDataView from "../../components/details-view/id-verification-data";
import BookingHistoryDataView from "../../components/details-view/booking-history";
import TripHistoryDataView from "../../components/details-view/trip-history";
import AuditTrailDataView from "../../components/details-view/audit-trail-data";
import AuditTrailDataFilter from "../../components/details-view/audit-trail-filter";
import { TabIdMap, useSelectedTab } from './useSelectedTab';
import TripHistoryDataFilter from '../../components/details-view/trip-history-filter';
import { removeAuthConfig } from "../../utils/auth-utils";
import { useAuth } from "react-oidc-context";
import AgreementsDataView from '../../components/details-view/agreements-data';


export default function DetailViewPage() {
    const { customerPathId } = useParams();
    const storeCustomerId = useAppSelector(selectId());
    const customer = useAppSelector(selectCustomer());
    const bookings = useAppSelector(selectBookings());
    const trips = useAppSelector(selectTrips());
    const auditTrails = useAppSelector(selectAuditTrail());

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const { user } = useAuth();

    const navigateToStartpage = () => {
        dispatch(setSearchTerm(''));
        dispatch(resetError(''));
        dispatch(resetCustomer(''));
        navigate('/');
    };

    const { selectedTabId, onTabClicked } = useSelectedTab();

    useEffect(() => {
        if (customerPathId && customerPathId != storeCustomerId) {
            dispatch(fetchCustomerDataById({ id: customerPathId }));
        }
    }, [customerPathId]);

    useEffect(() => {
        if (!selectedTabId) {
            return;
        }

        fetchTabData();
    }, [selectedTabId])

    const reloadPage = () => {
        if (!customerPathId) {
            return;
        }

        fetchTabData(true)
    }

    const logout = () => {
        removeAuthConfig();
        auth.removeUser();
        auth.signoutRedirect({
            id_token_hint: user?.id_token,
        });
        auth.clearStaleState();
    }

    const fetchTabData = (reload?: boolean) => {
        dispatch(setSearchTerm(''));
        dispatch(resetError(undefined));
        dispatch(resetErrorDialog(undefined));

        if (!customer || reload) {
            dispatch(resetCustomer(''));
            dispatch(fetchCustomerDataById({ id: customerPathId }));
        }

        if (selectedTabId === TabIdMap.BookingHistory) {
            if (!bookings?.data?.length || reload) dispatch(fetchCustomerBookings({ id: customerPathId }));
        } else if (selectedTabId === TabIdMap.AuditTrail) {
            if (!auditTrails?.data?.length || reload) dispatch(fetchCustomerAuditTrail({ id: customerPathId }));
        } else if (selectedTabId === TabIdMap.TripHistory) {
            if (!trips?.data?.length || reload) dispatch(fetchCustomerTrips({ id: customerPathId }));
        } else if (selectedTabId === TabIdMap.PersonalData) {
            if (!bookings?.data?.length || reload) dispatch(fetchCustomerBookings({ id: customerPathId }));
            if (!trips?.data?.length || reload) dispatch(fetchCustomerTrips({ id: customerPathId }));
        }
    }

    const handleOnChangeSelected = (selectedTab) => {
        onTabClicked(selectedTab);
    }

    return (
        <div className="App">
            <div className="column">
                <div className="c-header__wrapper" data-testid="heading">
                    <div data-testid="CustomerDetailsLabel" className='c-header  c-header--static'>
                        <div className='c-header__content-wrapper'>
                            <LogoBanner
                                imageProps={{
                                    alt: 'Back-Office MobilityApp',
                                    src: "https://cdn.bronson.vwfs.tools/bluelabel/v/15.6.0/img/logo.svg",
                                }}
                                onClick={navigateToStartpage}
                                className='banner'
                                logoHeight='180px'
                            />
                            <div className='banner-heading'>
                                <Heading level={6} className="display-inline">
                                    Back-Office MobilityApp
                                </Heading>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='navigation-bar'>
                    <div className='navigation-left'>
                        <div className='navigation-button' data-testid="back-button">
                            <Button icon='semantic-back' name='semantic-back' ariaLabel='Back' secondary
                                onClick={navigateToStartpage} />
                        </div>
                        <div className='navigation-button' data-testid="reload-button">
                            <Button icon='semantic-reload' ariaLabel='Reload' name='semantic-reload' secondary
                                onClick={reloadPage} />
                        </div>
                    </div>
                    <div className='navigation-right'>
                        <div className='navigation-button' data-testid="logout-button">
                            <Button icon='logout' ariaLabel='Logout' name='logout' secondary
                                onClick={logout} />
                        </div>
                    </div>
                </div>
                <div id="body-content" className="container">
                    <div className="column">
                        <div className="detail-container">
                            <Tabs selected={selectedTabId} onChangeSelected={handleOnChangeSelected}>
                                <Tabs.Header testId='tabsHeaders'>
                                    <Tabs.HeaderItem tabId={TabIdMap.PersonalData} icon="user" className="details-tab" >
                                        &nbsp;&nbsp;Personal Data
                                    </Tabs.HeaderItem>
                                    <Tabs.HeaderItem tabId={TabIdMap.IdVerificationData} icon="account-detail" className="details-tab" >
                                        &nbsp;&nbsp;ID Verification Data
                                    </Tabs.HeaderItem>
                                    <Tabs.HeaderItem tabId={TabIdMap.PaymentData} icon="coins-circle-eurosign" className="details-tab" >
                                        &nbsp;&nbsp;Payment Data
                                    </Tabs.HeaderItem>
                                    <Tabs.HeaderItem tabId={TabIdMap.BookingHistory} icon="fleet" className="details-tab" testId="bookingTabItem" >
                                        &nbsp;&nbsp;Booking History
                                    </Tabs.HeaderItem>
                                    <Tabs.HeaderItem tabId={TabIdMap.TripHistory} icon="fleet" className="details-tab" testId="tripHistoryTabItem" >
                                        &nbsp;&nbsp;Trip History
                                    </Tabs.HeaderItem>
                                    <Tabs.HeaderItem tabId={TabIdMap.AuditTrail} icon="details" className="details-tab" testId="auditTrailTabItem" >
                                        &nbsp;&nbsp;Audit Trail
                                    </Tabs.HeaderItem>
                                    <Tabs.HeaderItem tabId={TabIdMap.Agreements} icon="details" className="details-tab" testId="agreementsTabItem" >
                                        &nbsp;&nbsp;Agreements
                                    </Tabs.HeaderItem>
                                </Tabs.Header >
                                <Tabs.Content>
                                    <Tabs.ContentItem aria-labelledby={TabIdMap.PersonalData} tabId={TabIdMap.PersonalData}>
                                        <div className="customer-detail">
                                            <PersonalDataView />
                                        </div>
                                    </Tabs.ContentItem>
                                    <Tabs.ContentItem aria-labelledby={TabIdMap.IdVerificationData} tabId={TabIdMap.IdVerificationData}>
                                        <div className="customer-detail">
                                            <IdVerificationDataView />
                                        </div>
                                    </Tabs.ContentItem>
                                    <Tabs.ContentItem aria-labelledby={TabIdMap.PaymentData} tabId={TabIdMap.PaymentData}>
                                        <div className="customer-detail">
                                            <PaymentDataView />
                                        </div>
                                    </Tabs.ContentItem>
                                    <Tabs.ContentItem aria-labelledby={TabIdMap.BookingHistory} tabId={TabIdMap.BookingHistory}>
                                        <div className="customer-detail">
                                            <BookingHistoryDataView />
                                        </div >
                                    </Tabs.ContentItem >
                                    <Tabs.ContentItem aria-labelledby={TabIdMap.TripHistory} tabId={TabIdMap.TripHistory}>
                                        <div className="customer-detail">
                                            <TripHistoryDataFilter />
                                        </div>
                                        <div className="customer-detail">
                                            <TripHistoryDataView />
                                        </div>
                                    </Tabs.ContentItem>
                                    <Tabs.ContentItem aria-labelledby={TabIdMap.AuditTrail} tabId={TabIdMap.AuditTrail}>
                                        <div className="customer-detail">
                                            <AuditTrailDataFilter />
                                        </div>
                                        <div className="customer-detail">
                                            <AuditTrailDataView />
                                        </div>
                                    </Tabs.ContentItem>
                                    <Tabs.ContentItem aria-labelledby={TabIdMap.Agreements} tabId={TabIdMap.Agreements}>
                                        <div className="customer-detail">
                                            <AgreementsDataView />
                                        </div>
                                    </Tabs.ContentItem>
                                </Tabs.Content >
                            </Tabs >
                        </div >
                    </div >
                </div >
            </div >
            <Spinner fullPage busy={customer.status == 'loading'} testId='searchSpinner' />
        </div >
    );
}
