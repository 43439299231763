import React from 'react'
import { Modal } from '@vwfs-bronson/bronson-react'

interface MessageModalInterface {
  message: string,
  showModal: boolean,
  title?: string,
  action?: string,
  handleOnClose: () => void
  handleOnConfirm: () => void,
}

export default function MessageModal({ handleOnConfirm, showModal, handleOnClose, message, action, title }: MessageModalInterface) {
  const renderMessage = () => {

    return (
      <p data-testid='test-modal-message'>
        {message}
      </p>
    )
  }

  return <>
    <Modal
      shown={showModal}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      buttonConfirmLabel={action ? action : 'Ok'}
      buttonConfirmText={action ? action : 'Ok'}
      title={title ? title : `Action`}
      large={false}
      status='info'
      testId='test-message-modal'>
      {renderMessage()}
    </Modal>
  </>
}
