import React from 'react';
import {
  FormField,
  DatePicker,
  Button,
  Select
} from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setTripsDateRageFilter,
  setTripsPaymentState,
  selectTrips
} from "../../../redux/slices/customer-slice";
import { FilterByDateRange } from '../../../redux/slices/types';
import { FilterPaymentState, TripPaymentState } from '../trip-history/helper';

export default function TripHistoryDataFilter() {
  const dispatch = useAppDispatch();
  const trips = useAppSelector(selectTrips());

  function setDateRange(dateRange: FilterByDateRange) {
    dispatch(setTripsDateRageFilter(dateRange));
  }

  function setPaymentState(paymentState: FilterPaymentState) {
    dispatch(setTripsPaymentState(paymentState));
  }

  function handleOnDateRangeChange(e) {
    if (e.includes('bis')) {
      const SPLIT_FLAG = ' bis ';
      const dates = e.split(SPLIT_FLAG);
      setDateRange({
        start: dates[0],
        stop: dates[1],
      })
    }
  }

  function handleResetFilters() {
    setDateRange(undefined);
    setPaymentState('ALL')
  }

  return (
    <>
      <div data-testid="tripsFilter" key="tripsFilter" className="filter-group">
        <FormField
          labelText="Select Date Range"
          type="input"
          testId='testSelectDateRange'
        >
          <div style={{ margin: 'auto' }}>
            <DatePicker
              testId='testDatePicker'
              key="tripsDataRangeSelect"
              mode='range'
              value={trips.filter?.dateRange ? `${trips.filter.dateRange.start} bis ${trips.filter.dateRange.stop}` : undefined}
              onChange={handleOnDateRangeChange}
            />
          </div>
        </FormField>
        <FormField
          labelText="Select Payment State"
          type="input"
          testId='testSelectPaymentState'
        >
          <Select onChange={(e) => setPaymentState(e.target.value as FilterPaymentState)} value={trips?.filter?.paymentState || 'ALL'}>
            <Select.Item value={'ALL'}>All</Select.Item>
            {Object.keys(TripPaymentState).map(key => <Select.Item key={key} value={key}>{TripPaymentState[key]}</Select.Item>)}
          </Select>
        </FormField >
        <FormField
          labelText="Action"
          type="input"
        >
          <Button
            onClick={handleResetFilters}
            key="resetTripFilters"
            testId='testResetFiltersBtn'
          >
            Reset filters
          </Button>
        </FormField >
      </div >
      <span data-testId='testOpenPayments'>
        <b style={{ marginRight: '2px' }}>Open Payments:</b>
        <span>
          {trips?.hasOpenPayments !== null && trips.hasOpenPayments ? 'YES' : 'NO'}
        </span>
      </span>
    </>
  );
}
