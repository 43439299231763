import React from 'react';
import './style.css';
import {Button, Heading, LogoBanner} from '@vwfs-bronson/bronson-react';
import {setBifrostAuthConfig, setEmobgAuthConfig} from "../../utils/auth-utils";
import bifrostLogo  from '../../images/bifrost.png'
import emobgLogo  from '../../images/emobg.png'


export default function LoginViewPage() {
  const handleEmobgLogin = () => {
    setEmobgAuthConfig();
    window.location.reload();
  }

  const handleBifrostLogin = () => {
    setBifrostAuthConfig();
    window.location.reload();
  }

  return (
    <div className="App">
      <div className="column">
        <div className="c-header__wrapper" data-testid="heading">
          <div data-testid="LoginLabel" className='c-header  c-header--static'>
            <div className='c-header__content-wrapper'>
              <LogoBanner
                imageProps={{
                  alt: 'Back-Office MobilityApp',
                  src: "https://cdn.bronson.vwfs.tools/bluelabel/v/15.6.0/img/logo.svg",
                }}
                className='banner'
                logoHeight='180px'
              />
              <div className='banner-heading' data-testid="HeaderHeading">
                <Heading level={6} className="display-inline">
                  Back-Office MobilityApp
                </Heading>
              </div>
            </div>
          </div>
        </div>
        <div id="body-content" className="container">
          <div className="login-container" data-testid="login-container">
            <div className='row1col2' data-testid="bifrost-logo"><img src={bifrostLogo} className="login-logo" onClick={handleBifrostLogin} /></div>
            <div className='row1col4' data-testid="emobg-logo"><img src={emobgLogo} className="login-logo" onClick={handleEmobgLogin}/></div>
            <div className='row2col2' data-testid="bifrost-button">
              <Button
                className='blue-button'
                data-testid='loginEmobg'
                onClick={handleBifrostLogin}
                title='Bifrost'
                icon='semantic-login'
              >
                Login with Bifrost
              </Button>
            </div>
            <div className='row2col4' data-testid="emobg-button">
              <Button
                className='blue-button'
                data-testid='loginEmobg'
                onClick={handleEmobgLogin}
                title='Emobg'
                icon='semantic-login'
              >
                Login with EMG
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
